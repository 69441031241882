/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveArticle } from "@dataResolvers";
import { Post as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query ($slug: [String]) {
    craft {
      entry(section: "post", slug: $slug) {
        ... on Craft_post_post_Entry {
          #Meta
          uid
          url
          slug
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #Hero
          title
          authors {
            title
            slug
          }
          categories {
            title
            slug
          }
          postDate
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          descriptor0
          #Content Builder
          contentBuilder {
            ... on Craft_contentBuilder_copy_BlockType {
              type: typeHandle
              uid
              copy
            }
            ... on Craft_contentBuilder_quote_BlockType {
              type: typeHandle
              uid
              quote
              quoteAuthor
              quoteAuthorImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_contentBuilder_form_BlockType {
              type: typeHandle
              uid
              heading
              descriptor
              buttonText:button
            }
            ... on Craft_contentBuilder_cta_BlockType {
              type: typeHandle
              uid
              heading
              descriptor
              button {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
            ... on Craft_contentBuilder_image_BlockType {
              type: typeHandle
              uid
              caption
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_contentBuilder_video_BlockType {
              type: typeHandle
              uid
              videoUrl
              caption
            }
          }
          sidebar {
            ... on Craft_sidebar_relatedArticles_BlockType {
              type: typeHandle
              uid
              relatedArticle {
                title
                url
                ... on Craft_post_post_Entry {
                  descriptor0
                  categories {
                    ...on Craft_blogCategories_Category {
                      title
                      slug
                    }
                  }
                  heroImage {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  url
                  postDate
                  authors {
                    ... on Craft_authors_authors_Entry {
                      title
                      slug
                    }
                  }
                }
              }
              
            }
            ... on Craft_sidebar_relatedCaseStudies_BlockType {
              type: typeHandle
              uid
              relatedCaseStudy {
                title
                url
                ... on Craft_project_project_Entry {
                  heroBackgroundImage {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  projectCategories {
                    ... on Craft_projectCategories_Category {
                      title
                      slug
                    }
                  }
                }
              }
            }
            ... on Craft_sidebar_form_BlockType {
              type: typeHandle
              uid
              heading
              descriptor
              buttonText:button
            }
            ... on Craft_sidebar_cta_BlockType {
              type: typeHandle
              uid
              heading
              descriptor
              button {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `
  query ($slug: [String]) {
    craft {
      entry(section: "post", slug: $slug) {
        ... on Craft_post_post_Entry {
          #Meta
          uid
          url
          slug
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #Hero
          title
          authors {
            title
            slug
          }
          categories {
            title
            slug
          }
          postDate
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          descriptor0
          #Content Builder
          contentBuilder {
            ... on Craft_contentBuilder_copy_BlockType {
              type: typeHandle
              uid
              copy
            }
            ... on Craft_contentBuilder_quote_BlockType {
              type: typeHandle
              uid
              quote
              quoteAuthor
              quoteAuthorImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_contentBuilder_form_BlockType {
              type: typeHandle
              uid
              heading
              descriptor
              buttonText:button
            }
            ... on Craft_contentBuilder_cta_BlockType {
              type: typeHandle
              uid
              heading
              descriptor
              button {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
            ... on Craft_contentBuilder_image_BlockType {
              type: typeHandle
              uid
              caption
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
            ... on Craft_contentBuilder_video_BlockType {
              type: typeHandle
              uid
              videoUrl
              caption
            }
          }
          sidebar {
            ... on Craft_sidebar_relatedArticles_BlockType {
              type: typeHandle
              uid
              relatedArticle {
                title
                url
                ... on Craft_post_post_Entry {
                  descriptor0
                  categories {
                    ...on Craft_blogCategories_Category {
                      title
                      slug
                    }
                  }
                  heroImage {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  url
                  postDate
                  authors {
                    ... on Craft_authors_authors_Entry {
                      title
                      slug
                    }
                  }
                }
              }
              
            }
            ... on Craft_sidebar_relatedCaseStudies_BlockType {
              type: typeHandle
              uid
              relatedCaseStudy {
                title
                url
                ... on Craft_project_project_Entry {
                  heroBackgroundImage {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  projectCategories {
                    ... on Craft_projectCategories_Category {
                      title
                      slug
                    }
                  }
                }
              }
            }
            ... on Craft_sidebar_form_BlockType {
              type: typeHandle
              uid
              heading
              descriptor
              buttonText:button
            }
            ... on Craft_sidebar_cta_BlockType {
              type: typeHandle
              uid
              heading
              descriptor
              button {
                ariaLabel
                customText
                target
                text
                title
                type
                url
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  return resolveArticle(craft.entry)
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
